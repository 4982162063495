<template>
    <App title="Data Import & Export">
        <div class="content">
            <div class="row">
                <div class="col-6">
                    <Block>
                        <template #header>
                            <h3 class="block-title">Data Export</h3>
                        </template>
                        <div class="col">
                            <Form ref="form" @submit="onSubmit">
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="users" name="users" label="Users" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="vaults" name="vaults" label="Stacs Vaults" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="easies" name="easies" label="Easy Stacs" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="qualifications" name="qualifications" label="Pre Qualifications" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="rnpls" name="rnpls" label="RNPL's" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="zeros" name="zeros" label="Stacs Zeros" :inline="true" />
                                    </div>
                                </div>

                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="transactions" name="transactions" label="Transactions" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="accounts" name="accounts" label="Bank Accounts" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="kins" name="kins" label="Next of Kin's" :inline="true" />
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="employments" name="employments" label="Employments" :inline="true" />
                                    </div>
                                </div>
                                <!-- <div class="row g-3">
                                    <div class="col-12">
                                        <CheckField v-model="cards" name="cards" label="Bank Cards" :inline="true" />
                                    </div>
                                </div> -->
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn btn-primary me-1 mb-3" />
                                </div>
                            </Form>

                        </div>
                    </Block>
                </div>
                <div class="col-6">
                    <Block>
                        <template #header>
                            <h3 class="block-title">Data Import</h3>
                        </template>
                        <div class="row">
                            <div class="col">
                                <Dropzone class="mx-3" param="data" label="Import Data" :url="route('data.ajax.import')" />
                            </div>
                        </div>
                    </Block>
                </div>
            </div>
        </div>
    </App>
</template>
<script lang="ts">
import { error } from '@/ts/app'
import Block from '@/vue/components/Block.vue'
import Button from '@/vue/components/Button.vue'
import CheckField from '@/vue/components/CheckField.vue'
import Dropzone from '@/vue/components/Dropzone.vue'
import Form from '@/vue/components/Form.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Component, Ref, toNative, Vue } from 'vue-facing-decorator'
import { ButtonLabel } from '../components/Button'


@Component({
    components: {
        App,
        Block,
        Dropzone,
        Form,
        CheckField,
        Button
    }
})
class Data extends Vue {

    message = ''

    users = true

    vaults = true
    easies = true
    qualifications = true
    rnpls = true
    zeros = true
    transactions = true
    kins = false
    employments = false
    accounts = false
    cards = false

    loading = false
    done = false

    @Ref('form')
    readonly form!: InstanceType<typeof Form>


    get labels(): ButtonLabel {
        return {
            normal: 'Send to Email',
            loading: 'Sending....',
            done: 'Sent!',
        }
    }

    onSubmit(payload) {
        this.loading = true
        this.$http
            .post(this.route('data.ajax.export'), payload)
            .then(({ data }) => this.done = true)
            .catch((e: AxiosError) => error(e, this))
            .finally(() => this.loading = false)
    }
}

export default toNative(Data)
</script>
