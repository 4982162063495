/*
 *  Document   : app.js
 *  Author     : pixelcave
 *  Description: Main entry point
 *
 */

// Import required modules
import Template from './modules/template.js'

export default class App extends Template {
	/*
	 * Auto called when creating a new instance
	 *
	 */
	constructor() {
		super()
	}

	onLoad(fn) {
		if (document.readyState === 'complete') {
			fn()
		} else {
			//window.onload = fn
			document.addEventListener('DOMContentLoaded', fn)
		}
	}
}

// Create a new instance of App
//window.One = new App({ darkMode: 'system' }) // Default darkMode preference: "on" or "off" or "system"
