<template>
    <!-- Header -->
    <header id="page-header">
        <!-- Header Content -->
        <div class="content-header">
            <!-- Left Section -->
            <div class="d-flex align-items-center">
                <!-- Toggle Sidebar -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
                <button type="button" class="btn btn-sm btn-alt-secondary me-2 d-lg-none" data-toggle="layout" data-action="sidebar_toggle">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
                <!-- END Toggle Sidebar -->

                <!-- Toggle Mini Sidebar -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
                <!-- <button type="button" class="btn btn-sm btn-secondary bg-dark text-light me-2 d-none d-lg-inline-block" data-toggle="layout" data-action="sidebar_mini_toggle">
					<i class="fa fa-fw fa-bars"></i>
				</button> -->
                <!-- END Toggle Mini Sidebar -->

                <!-- Open Search Section (visible on smaller screens) -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                <!--<button type="button" class="btn btn-sm btn-alt-secondary d-md-none" data-toggle="layout" data-action="header_search_on">
                    <i class="fa fa-fw fa-search"></i>
                </button> -->
                <!-- END Open Search Section -->

                <!-- Search Form (visible on larger screens) -->
                <!-- <form class="d-none d-md-inline-block" @submit.prevent="onSearch">
                    <div class="input-group input-group">
                        <input v-model="search" type="text" class="form-control form-control-alt" placeholder="Search..">
                        <span class="input-group-text border-0">
                            <i class="fa fa-fw fa-search"></i>
                        </span>
                    </div>
                </form> -->
                <!-- END Search Form -->
            </div>
            <!-- END Left Section -->
        </div>
        <!-- END Header Content -->

        <!-- Header Search -->
        <!-- <div id="page-header-search" class="overlay-header bg-body-extra-light">
            <div class="content-header">
                <form class="w-100" @submit.prevent="onSearch">
                    <div class="input-group">
                        <button type="button" class="btn btn-alt-danger" data-toggle="layout" data-action="header_search_off">
                            <i class="fa fa-fw fa-times-circle"></i>
                        </button>
                        <input v-model="search" type="text" class="form-control" placeholder="Search or hit ESC..">
                    </div>
                </form>
            </div>
        </div> -->
        <!-- END Header Search -->

        <!-- Header Loader -->
        <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
        <div id="page-header-loader" class="overlay-header bg-body-extra-light">
            <div class="content-header">
                <div class="w-100 text-center">
                    <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                </div>
            </div>
        </div>
        <!-- END Header Loader -->
    </header>
    <!-- END Header -->
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({})
class Header extends Vue {
    search = ''

    created() {
        this.search = ''
    }

    onSearch() {
        this.router.visit(this.route("search", { search: this.search }))
    }
}
export default toNative(Header)
</script>
