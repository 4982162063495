<template>
    <!-- Footer -->
    <footer id="page-footer" class="bg-body-light text-muted fs-sm">
        <div class="content py-3">
            <div class="row">
                <div class="col-sm-6 order-sm-1 text-center d-flex justify-content-start align-items-center">
                    <span class="ms-2">Stacs &copy; {{ year }}</span>
                </div>
            </div>
        </div>
    </footer>
    <!-- END Footer -->
</template>

<script lang="ts">
import { Component, toNative, Vue } from 'vue-facing-decorator'

@Component({})
class Footer extends Vue {
    get year() {
        return new Date().getFullYear()
    }
}

export default toNative(Footer)
</script>

<style lang="scss" scoped>
.logo {
    width: 30px;
    aspect-ratio: 1;
    object-fit: contain;
}
</style>
